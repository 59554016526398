.BodyBuildingBuddy {
  --table-bg-dark: rgb(70, 111, 156);
  --td-bg: rgb(73, 151, 184);
  --td-bg-hover: rgb(99, 182, 218);
  --button: #DC9853;
  --button-hover: #BA6460;
  --button-active: rgb(192, 66, 59);
  text-align: center;
}

.BodyBuildingBuddy table {
  margin: auto;
  padding: 0;
  border-spacing: 2px;
  background-color: var(--table-bg-dark);
  min-width: 300px;
}

.BodyBuildingBuddy table.padded td {
  padding: 4px
}

.BodyBuildingBuddy table thead tr:first-child {
  background: var(--table-bg-dark);
  font-size: 1.2em;
  color: #ddd;
}

.BodyBuildingBuddy tr {
  background-color: var(--td-bg);
}

.BodyBuildingBuddy tr:hover {
  background-color: var(--td-bg-hover);
}

.BodyBuildingBuddy td {
  text-align: center;
}

.BodyBuildingBuddy input {
  border-radius: 8px;
  padding: 8px;
}

.BodyBuildingBuddy input[type=number] {
  width: 4em;
}

.BodyBuildingBuddy .BigButton {
  display: inline-block;
  background: var(--button);
  border-radius: 8px;
  padding: 8px;
  margin: 4px 2px;
  
  /* unselectable */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.BodyBuildingBuddy .BigButton:hover {
  background: var(--button-hover);
}

.BodyBuildingBuddy .BigButton:active {
  background: var(--button-active);
}