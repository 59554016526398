/* This file contains css for miscellaneous componenets, not necessary for the basic page template */
.Featured {
    background-color: #000;
    margin-top: 5%;
    width: 100%;
}

.Featured .MainPanel {
    width: 100%;
    height: 480px;
}

.Featured .Tab {
    display: inline-block;
    padding-top: 20%;
    width: 20%;
    /* border: 1px solid #f00; */
    /* background-image: url('/res/eyes.png'); */
    /* background-size: cover; */
}

.Featured .Tab img {
    position: absolute;
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover;
}