body {
  background-color: #200020;
  background-image: linear-gradient(270deg, #ff9a9e, #fad0c4, #f6d365, #84fab0, #8fd3f4, #a6c1ee, #cbb4d5, #e06666);
  background-image: url("/public/res/tiled bg.png");
  /* animation: bg_rainbow 6s ease-in-out infinite; */
  animation: bg_scroll 4s linear infinite;
}

@keyframes bg_scroll {
  0% { background-position: left 0% bottom 0% }
  100% { background-position: left 64px bottom 64px }
}

.App {
  max-width: 960px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}

header {
  display: flex;
  flex-wrap: wrap;
  background: #000;
}

header h1 {
  text-align: center;
  margin: 0 8px;
  padding: 4px;
}

header .homeBtn:hover {
  background-color: #692069;
}

header span:hover {
  color: #fff !important;
}

header a {
  text-decoration: none;
  outline: 0;
  color: #692069;
}

header a:hover {
  text-decoration: none;
  color: #fff;
}

nav {
  display: flex;
  flex-grow: 1;
}

.dropdown {
  position: relative;
  flex-grow: 1;
  min-width: 60px;
  min-height: 2em;
}

.drop-button {
  height: 100%;
}

.drop-button a {
  text-decoration: none;
  color: #fff;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  
  text-align: center;
  background-color: #000;
}

.drop-button a:hover {
  background-color: #692069;
}

.drop-button a:active {
  background-color: #202096;
}

.drop-content {
  /* display: none; */
  transform-origin: top;
  transform:scaleY(0);
  overflow: hidden;
  transition: transform 0.1s ease-out;
  position: absolute;
  background: #000;
  width: 100%;
  z-index: 1;
}

.drop-button:hover .drop-content {
  /* display: block; */
  transform:scaleY(1);
}

.drop-element {
  width: 100%;
  padding: 4px 0;
}

.drop-element:hover {
  background: #692069;
}

.drop-element:active {
  background: #202096;
}

.page {
  background-color: #fff;
  padding: 6em;
}

.page a {
  outline: 0;
  color: #202096;
  text-decoration: none;
  font-weight: bold;
}

.page a:hover {
  text-decoration: underline;
}

.page a:active {
  color: #962020;
}

.page a img {
  box-sizing: border-box;
  border: 1px solid #202096;
}

.page a img:hover {
  border: 1px dashed #202096;
}

footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 8px;
}

/* footer:hover {
  background-color: #692069;
  color: #fff;
} */

footer a {
  text-decoration: none;
  color: #fff;
}

.ContactMe input, .ContactMe textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #692069;
  width: 100%;
}

table {
  border-collapse: collapse;
}

table, td {
  padding: 8px;
  border: 1px solid #000;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
}
  
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 4px;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.padded10 {
  padding: 10%;
}

div.spacer10M32 {
  height: 10%;
  min-height: 32px;
}

div.flexRow {
  display: flex;
}

/* @media screen and (max-width:640) {
  div.flexRow {
    display: block;
  }
} */

div.flexCol {
  flex-basis: 100%;
}

h2 {
  margin-top:0;
}

.highlight {
  background: #ccc;
  border: 2px solid #ccc;
  border-radius: 3px;
}

@keyframes bg_rainbow {
  15% { background-color: #a0a; }
  30% { background-color: #0aa; }
  45% { background-color: #0a0; }
  60% { background-color: #aa0; }
  75% { background-color: #a60; }
  90% { background-color: #a00; }
 }

 @media (orientation: portrait) {
  header {
    flex-direction: column;
  }

  .page {
    padding: 2em;
  }
}

.mediaBadges {
  padding: 1em;
}

.badgeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.badgeButton img {
  height: 2em;
}

.badgeButton * {
  display: inline;
  margin-left: .2em;
  margin-right: .2em;
}

@keyframes badgeJiggle {
  0% { transform: rotate(0deg) scale(1) }
  25% { transform: rotate(4deg) scale(1.125) }
  100% { transform: rotate(0deg) scale(1) }
}

.badgeButton:hover {
  animation: badgeJiggle ease-in-out .25s;
  /* background-color: #692069; */
}